import axios from 'axios';
import { TOOLBOX_API_URL } from '../../lib/environment';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getMls: (data) => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');

			const controller = new AbortController();

			let timeout = setTimeout(() => {
				controller.abort();
			}, 180000);

			axios({
				url: `${TOOLBOX_API_URL}/mls/mls-table`,
				method: 'GET',
				cache: 'no-cache',
				data: data
				, signal: controller.signal
			})
				.then((resp) => {
					clearTimeout(timeout);

					resolve(resp.data.result);
				})
				.catch((err) => {
					clearTimeout(timeout);

					reject(err);
				});
		});
	},
	getMlsStats: (data) => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');

			const controller = new AbortController();

			let timeout = setTimeout(() => {
				controller.abort();
			}, 180000);

			axios({
				url: `${TOOLBOX_API_URL}/mls/AGG/stats`,
				method: 'GET',
				cache: 'no-cache',
				data: data
				, signal: controller.signal
			})
				.then((resp) => {
					clearTimeout(timeout);

					resolve(resp.data.result);
				})
				.catch((err) => {
					clearTimeout(timeout);

					reject(err);
				});
		});
	},
};