import axios from 'axios';
import Cookies from 'js-cookie';
import { isNil, isEmpty } from 'lodash';
import { ACCOUNT_API_URL } from '../../lib/environment';
import { getAccess } from '../access';

export default {
	create: (data) => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');

			const controller = new AbortController();

			let timeout = setTimeout(()=>{
				controller.abort();
			}, 180000);

			axios({
				url: `${ACCOUNT_API_URL}/customers`,
				method: 'POST',
				cache: 'no-cache',
				// headers: {
				// 	'Authorization': `Bearer ${bayToken}`
				// },
				data: data
				, signal: controller.signal
			})
			.then((resp) => {
				clearTimeout(timeout);

				resolve(resp.data.result);
			})
			.catch((err) => {
				clearTimeout(timeout);

				reject(err);
			});
		});
	},

	read: (customerId, queryParams, limit, offset, sort) => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');

			const controller = new AbortController();

			let timeout = setTimeout(()=>{
				controller.abort();
			}, 180000);


			let params = [];

			if(queryParams){
				params.push(`q=${JSON.stringify(queryParams)}`);
			}

			params.push('limit=' + (limit ? limit : '100'));
			params.push('offset=' + (offset ? offset : '0'));
			params.push('sort=' + (sort ? JSON.stringify(sort) : '{"name":"asc"}'));

			if (isNil(customerId)) {
				axios({
					url: `${ACCOUNT_API_URL}/customers/?${params.join('&')}`
					, method: 'GET'
					, cache: 'no-cache'
					// , headers: {
					// 	'Authorization': `Bearer ${bayToken}`
					// }
					, signal: controller.signal
				})
				.then(resp => {
					clearTimeout(timeout);

					if(resp.data.status === 0){
						resolve(resp.data)
					}else{
						reject(resp.data);
					}
				})
				.catch(err => {
					clearTimeout(timeout);

					reject(err);
				});
			} else {
				axios({
					url: `${ACCOUNT_API_URL}/customers/${customerId}`
					, method: 'GET'
					, cache: 'no-cache'
					// , headers: {
					// 	'Authorization': `Bearer ${bayToken}`
					// }
					, signal: controller.signal
				})
				.then(resp => {
					clearTimeout(timeout);

					if(resp.data.status === 0){
						resolve(resp.data.result)
					}else{
						reject(resp.data);
					}
				})
				.catch(err => {
					clearTimeout(timeout);

					reject(err);
				});
			}
		});
	},

	update: (customerId, customerData) => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');

			const controller = new AbortController();

			let timeout = setTimeout(()=>{
				controller.abort();
			}, 180000);

			axios({
				url: `${ACCOUNT_API_URL}/customers/${customerId}`,
				method: 'PATCH',
				cache: 'no-cache',
				// headers: {
				// 	'Authorization': `Bearer ${bayToken}`
				// },
				data: customerData,
				signal: controller.signal
			})
			.then((resp) => {
				clearTimeout(timeout);

				resolve(resp.data.result);
			})
			.catch((err) => {
				clearTimeout(timeout);

				reject(err);
			});
		});
	},

	delete: (customerId) => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');

			const controller = new AbortController();

			let timeout = setTimeout(()=>{
				controller.abort();
			}, 180000);

			axios({
				url: `${ACCOUNT_API_URL}/customers/${customerId}`,
				method: 'DELETE',
				cache: 'no-cache',
				// headers: {
				// 	Authorization: `token ${bayToken}`
				// },
				signal: controller.signal
			})
			.then((resp) => {
				clearTimeout(timeout);

				if (resp.data.status === 0) {
					resolve(resp.data.result);
				} else {
					reject(resp.data);
				}
			})
			.catch((err) => {
				clearTimeout(timeout);

				reject(err);
			});
		});
	},

	archive: (projectId)=>{
		return this.update(projectId, {status:'archived'});
	},

	getQueryParams: (customer, params) => {
		let sp = {
			variant: 'design'
			, owner: [
				customer.id
				, (customer.role === 'Admin' ? 'backatyou' : null)
			]
			, search: params.search || ''
			, status: params.status || 'active'
			, tagKeys: (!isNil(params.tagKeys) && params.tagKeys.length !== 0)
				? params.tagKeys.split(',')
				: null
		};

		let queryParams = {
			variant: sp.variant
			, owner: {
				'$in': Array.isArray(sp.owner) ? sp.owner : sp.owner.split(',')
			}
		};

		if (!isNil(sp.search) && sp.search !== '') {
			queryParams.name = {
				'$regex': `${sp.search}`
				, '$options': 'i'
			};
		}

		if (!isNil(sp.tagKeys) && !isEmpty(sp.tagKeys)) {
			queryParams.tagKeys = {
				'$in': Array.isArray(sp.tagKeys) ? sp.tagKeys : sp.tagKeys.split(',')
			};
		}

		if (!isNil(sp.status)) {
			queryParams.status = sp.status;
		}

		if (!isNil(sp.sortBy) && !isNil(sp.sortDir)) {
			queryParams[sp.sortBy] = sp.sorDir;
		}

		if (!isNil(sp.status)) {
			queryParams.status = sp.status;
		}

		if (!isNil(sp.limit)) {
			queryParams.limit = sp.limit;
		}

		if (!isNil(sp.offset)) {
			queryParams.offset = sp.offset;
		}

		return queryParams;
	}
};
