import React from 'react'
import { BsSortDown, BsSortUp, BsTruck, BsThreeDotsVertical, BsLayoutThreeColumns, BsSearch } from 'react-icons/bs';
import { HiOutlinePlay, HiOutlineStop, HiOutlineTrash } from "react-icons/hi2";
import { HiOutlineRefresh, HiOutlineMail } from "react-icons/hi";
import { PiImageThin } from "react-icons/pi";
import { RxDragHandleDots2 } from 'react-icons/rx';

export default function CustomIcon({ icon, ...rest }) {

    switch (icon) {
        case "start":
            return <HiOutlinePlay {...rest} />
        case "search":
            return <BsSearch {...rest} />
        case "stop":
            return <HiOutlineStop {...rest} />
        case "refresh":
            return <HiOutlineRefresh {...rest} />
        case "mail":
            return <HiOutlineMail {...rest} />
        case "truck":
            return <BsTruck {...rest} />
        case "imageFrame":
            return <PiImageThin {...rest} />
        case "trash":
            return <HiOutlineTrash {...rest} />
        case "vertical-ellipsis":
            return <BsThreeDotsVertical {...rest} />
        case "column-section":
            return <BsLayoutThreeColumns {...rest} />
        case "sortDown":
            return <BsSortDown {...rest} />
        case "sortUp":
            return <BsSortUp {...rest} />
        case "drag":
            return <RxDragHandleDots2 {...rest} />
        default:
            return <></>
    }
}
