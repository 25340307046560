import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { isNil } from "lodash";
import { ACCOUNT_APP_ID } from "../lib/environment";

const defaultAccess = {
	canLogin: false
	, isSystemAdmin: false
	, isSuperAdmin: false
	, isSuperUser: false
	, isAdmin: false
	, isStaff: false
	, isUser: false
};

export const getAccess = (user) => {
	let purview;
	let customerNumber;

	if(!user){
		const bayToken = Cookies.get('bayToken');
		const jwt = jwt_decode(bayToken);

		purview = jwt?.session.user.purview[ACCOUNT_APP_ID] || jwt?.session.user.purview['DEFAULT'];
		customerNumber = jwt?.session.user.customer.idNumber;
	}else{
		purview = user?.purview[ACCOUNT_APP_ID] || user?.purview['DEFAULT'];
		customerNumber = user?.customer.idNumber;
	}

	let access = {...defaultAccess};

	if(!isNil(purview)){
		access.canLogin = (purview.length !== 0);
		// User created templates
		access.isAdmin = (purview.indexOf('admin') !== -1);
		access.isStaff = (purview.indexOf('staff') !== -1);
		access.isUser = (purview.indexOf('user') !== -1);

		if(!isNil(customerNumber)){
			access.isSystemAdmin = (customerNumber === 0 && purview.indexOf('sys') !== -1);
			// Can see all templates
			access.isSuperAdmin = (customerNumber === 0 && purview.indexOf('superadmin') !== -1);
			// Can only see created templates for customers
			access.isSuperUser = (customerNumber === 0 && purview.indexOf('superuser') !== -1);
		}
	}


	access.role = (
		access.isSystemAdmin
		? 'System Admin'
		: (
			access.isSuperAdmin
			? 'Super Admin'
			: (
				access.isSuperUser
				? 'Super User'
				: (
					access.isAdmin
					? 'Admin'
					: (
						access.isStaff
						? 'Staff'
						: (
							access.isUser
							? 'User'
							: ''
						)
					)
				)
			)
		)
	);


	// console.log(access);

	return access;
};
