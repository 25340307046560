import React from 'react';
import Avatar from 'react-avatar';

import { Image, Modal, Button } from 'react-bootstrap';

import globalState from '../../../state/global';

export const ProfileImage = (props)=>{
	const userInfo = props.userInfo;
	const globalDispatch = globalState.useDispatch();

	const imgSize = props.size || '30';

	return (
		<>
			<div
				style={{
					display: 'flex'
					, justifyContent: 'center'
					, alignItems: 'center'
					, position: 'relative'
					, cursor: 'pointer'
				}}>

				{userInfo.img
					? (
						<Image
							src={userInfo.img}
							alt={userInfo.firstName + ' ' + userInfo.lastName + ' Profile Image'}
							roundedCircle
							style={{ height: imgSize + 'px', width: imgSize + 'px'}}
						/>
					)
					: (
						<Avatar
							name={userInfo.firstName + ' ' + userInfo.lastName}
							alt={userInfo.firstName + ' ' + userInfo.lastName + ' Profile Image'}
							size={imgSize}
						/>
					)
				}
			</div>
		</>
	);
}