import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { isNil } from 'lodash';

export const PageHeader = (props) => {
	return (
		<Row className="mb-5">
			<Col>
				<Row>
					<Col sm={isNil(props.children) ? 12 : 6}>
						<h2 className="mb-0">{props.title}</h2>
					</Col>
					{!isNil(props.children) && (
						<Col sm={6}>
							{props.children}
						</Col>
					)}
				</Row>
				{!isNil(props.description) && (
					<Row>
						<Col sm={12}>
							<p>{props.description}</p>
						</Col>
					</Row>
				)}
			</Col>
		</Row>
	);
};