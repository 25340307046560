import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setMainStats: (state, action) => {
            return { ...state, ...action.payload };
        },
    }
});

// Action creators are generated for each case reducer function
export const { setMainStats, } = homeSlice.actions;

export const getHomeState = (state) => state.home;

export default homeSlice.reducer;