import { Container, Row, Col, Card } from 'react-bootstrap';
import { isNil } from 'lodash';

import { Loading } from 'components/partials/loading/Loading';

const StatsSection = ({ fluid, statsList, statsLoading, statsTitles }) => {

    return (
        <Container className="pt-3" fluid={fluid}>
            {isNil(statsList) || statsLoading
                ? (
                    <Loading message="Loading" />
                )
                : (
                    <Row className='d-flex'>
                        {Object.keys(statsList).map((item, index) => (
                            <Col className='mb-2' key={item} xs={12} sm={6} md={6} lg={4} xl={3} >
                                <Card>
                                    <Card.Header>{statsTitles[item]}</Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            <h1 className="mb-0">{statsList[item]}</h1>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )
            }
        </Container>
    )
}

export default StatsSection