import React from "react";
import Form from 'react-bootstrap/Form';
import { Badge } from 'react-bootstrap';
import { Table } from 'ka-table';
import { kaPropsUtils } from 'ka-table/utils';
import { SortingMode, SortDirection } from 'ka-table/enums';
import { deselectAllFilteredRows, deselectRow, selectAllFilteredRows, selectRow, selectRowsRange, } from 'ka-table/actionCreators';

import ServiceQuickActions from "./quickActions";
import CustomIcon from "components/Icons";
import BaySpinner from "@back-at-you-inc/bay-ui/dist/BaySpinner";

const ServicesTable = ({
	tableProps,
	dispatch,
	services,
	setEnableActionModal,
	setSelectedAction,
	listLoad,

}) => {

	const SelectionCell = ({ rowKeyValue, dispatch, isSelectedRow, selectedRows }) => {
		return (
			<Form.Check
				className={'bay-checkbox ms-1 mt-0'}
				type={"checkbox"}
				checked={isSelectedRow}
				onChange={(event) => {
					if (event.nativeEvent.shiftKey) {
						dispatch(selectRowsRange(rowKeyValue, [...selectedRows].pop()));
					} else if (event.target.checked) {
						dispatch(selectRow(rowKeyValue));
					} else {
						dispatch(deselectRow(rowKeyValue));
					}
				}}
			/>
		);
	};

	const SelectionHeader = ({ dispatch, areAllRowsSelected }) => {
		const availableData = kaPropsUtils.getData(tableProps);
		return (
			<Form.Check
				className={'bay-checkbox ms-1 mt-0'}
				type={"checkbox"}
				checked={areAllRowsSelected}
				onChange={(event) => {
					if (event.target.checked && tableProps?.data?.length) {
						dispatch(selectAllFilteredRows());
					} else {
						dispatch(deselectAllFilteredRows());
					}
				}}
			/>
		);
	};

	return (
		<div className="data-grid mt-1">
			<div className="d-sm-block">
				<div>
					<div className="bay-data-grid mt-3">
						{<Table {...tableProps}
							dispatch={dispatch}
							loading={{
								enabled: listLoad,
								text: 'Loading data...',
							}}
							sortingMode={SortingMode.Single}
							noData={{
								text: tableProps?.isDataSet && !tableProps?.data?.length && 'No Data Found',
							}}
							//
							columnReordering={true}
							childComponents={{
								headCell: {
									content: (props) => {
										if (props.column.key === 'selection-cell') {
											return (
												<SelectionHeader
													{...props}
													areAllRowsSelected={tableProps?.data?.length ? kaPropsUtils.areAllFilteredRowsSelected(tableProps) : false}
												/>
											);
										}
									}
								},
								elementAttributes: (props) => {
									return {
										style: {
											...props.column.style,
										},
									};
								},
								headCellContent: {
									content: ({ column }) => {
										return (
											<div className="d-flex align-items-center w-100">
												{column?.key !== "name" && <CustomIcon className="dragColumn me-1" icon="drag" size={13} color="rgb(139, 141, 148)" />}
												<span>{column.title}</span>
											</div>
										);
									},
								},
								loading: {
									content: () => {
										return (
											<BaySpinner />
										)
									}
								},
								cellText: {
									content: (props) => {
										switch (props.column.key) {
											case 'selection-cell':
												return <SelectionCell {...props} />
											case 'name':
												return <div className="d-flex w-100 justify-content-between">
													<div className="bay-service-cell cursor-pointer align-items-center d-flex" style={{ height: '55px' }}>
														<div className="content services-text text-capitalize">
															{props?.rowData?.name}
														</div>
													</div>
													<div className="d-flex">
														<div className="vr"></div>
														<div className="px-2 d-flex align-items-center dot">
															<div className="col-action-button text-primary cursor-pointer dot py-1">
																<ServiceQuickActions
																	tableProps={tableProps}
																	setSelectedAction={setSelectedAction}
																	setEnableActionModal={setEnableActionModal}
																	rowData={props.rowData}
																	dispatch={dispatch}
																/>
															</div>
														</div>
													</div>
												</div>
											case 'health':
												return <Badge
													className="text-capitalize"
													bg={props?.rowData.health === 'Up' ? 'success' : 'danger'}
												>
													{props?.rowData.health === '' ? 'Unknown' : props?.rowData.health}
												</Badge>
											case 'status':
												return <Badge
													className="text-capitalize"
													bg={props?.rowData.status === 'Running' ? 'success' : 'danger'}
												>
													{props?.rowData.status}
												</Badge>
											case 'serviceName':
												return <div className="content text-capitalize">
													{props?.rowData?.serviceName}
												</div>
											case 'action':
												return <div className="content text-capitalize">
													{props?.rowData?.action}
												</div>
											default:
												break;
										}
									}
								},
								sortIcon: {
									content: ({ column }) => {
										return (
											<span
												style={{
													width: 12,
													position: 'relative',
													top: 1,
													paddingRight: '0.5rem',
												}}
											>
												{column.sortDirection === SortDirection.Ascend ? (
													<CustomIcon
														className="cursor-pointer"
														icon="sortUp"
														size={16} color="#5d5f69"
													/>
												) : (
													<CustomIcon
														className="cursor-pointer"
														icon="sortDown"
														size={16}
														color="#5d5f69" />
												)}
											</span>
										);
									},
								},
							}}
						/>}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ServicesTable