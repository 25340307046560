import CustomIcon from "components/Icons";

export const TOGGLE_LAYOUT = 'TOGGLE_LAYOUT';
export const SERVICES_GRID_FILTER = ['name', 'health', 'service name', 'status', 'action status'];

export const SERVICE_QUICK_ACTIONS = [
    { value: "Start", label: "Start", icon: <CustomIcon icon="start" size={20} /> },
    { value: "Stop", label: "Stop", icon: <CustomIcon icon="stop" size={20} /> },
    { value: "Rebuild", label: "Rebuild", icon: <CustomIcon icon="refresh" size={20} /> },
    { value: "Move", label: "Move", icon: <CustomIcon icon="truck" size={20} /> },
    { value: "Update", label: "Update", icon: <CustomIcon icon="imageFrame" size={20} /> },
    { value: "Remove", label: "Remove", icon: <CustomIcon icon="trash" size={20} /> }
]