import { add, isNil } from "lodash";
import React, { useState } from 'react';
import { Form, Card, Modal } from 'react-bootstrap';

export const Addresses = (props) => {
	const [addresses, setAddresses] = useState(props.addresses || [{
		street: ''
		, city: ''
		, postalCode: ''
		, state: ''
		, country: ''
		, isDefault: true
	}]);

	const onChange = (e) => {
		let target = e.currentTarget || e.target;

		let a = {...addresses[0]};

		a[target.name] = target.value;

		setAddresses([a]);

		const eventData = {
			target: {
				name: 'addresses'
				, value: [a]
			}
		}

		props.onChange(eventData);

		return;
	};

	return (
		<>
			{addresses.map((app, index) => (
				<Card className="mb-3" key={index}>
					<Card.Header>
						<Card.Title className="mb-0">Addresses</Card.Title>
					</Card.Header>
					<Card.Body>
						<Form.Group className="mb-3">
							<Form.Label>Street:</Form.Label>
							<Form.Control type="text" name="street" value={addresses?.[0]?.street || ''} onChange={onChange} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>City:</Form.Label>
							<Form.Control type="text" name="city" value={addresses?.[0]?.city || ''} onChange={onChange} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>State:</Form.Label>
							<Form.Control type="text" name="state" value={addresses?.[0]?.state || ''} onChange={onChange} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Postal Code:</Form.Label>
							<Form.Control type="text" name="postalCode" value={addresses?.[0]?.postalCode || ''} onChange={onChange} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Country:</Form.Label>
							<Form.Control type="text" name="country" value={addresses?.[0]?.country || ''} onChange={onChange} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Type:</Form.Label>
							<Form.Select aria-label="Default select example" value={addresses?.[0]?.type || ''} onChange={onChange}>
								{['Work', 'Home', 'Other'].map((type, index) => (
									<option key={index} value={type.toLowerCase()}>{type}</option>
								))}
							</Form.Select>
						</Form.Group>
					</Card.Body>
				</Card>
			))}
		</>
	);
};
