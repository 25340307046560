import React from 'react';
import { Container, Row, Col, Navbar, Nav, NavDropdown, Offcanvas, Image, Modal, Button } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';

import Cookies from 'js-cookie';

import { formatPhoneNumber } from '../../../modules/utils';
import { ProfileImage } from '../profile/ProfileImage';
import { ProfileMenu } from '../profile/ProfileMenu';

import globalState from '../../../state/global';

export const TopNav = ({ store }) => {
	const userState = globalState.useUserState();
	const actingAsState = globalState.useActingAsState();
	const globalDispatch = globalState.useDispatch();

	let navigate = useNavigate();

	const [showProfile, setShowProfile] = React.useState(false);

	const handleClose = (e) => {
		setShowProfile(false);
	}

	const handleShow = (e) => {
		setShowProfile(true);
	}

	const handleLogout = () => {
		Cookies.remove('bayToken');
		globalDispatch(globalState.resetUserState());
		window.location.href = '/';
	}

	const useClass = (pathName)=>{
		if(window.location.pathname === ('/' + pathName)){
			return 'text-bold';
		}else{
			return '';
		}
	}

	const UserProfile = ()=>{
		return (
			<Offcanvas show={showProfile} onHide={handleClose} placement="end">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Hello, {actingAsState.firstName}</Offcanvas.Title>
				</Offcanvas.Header>
				<Container>
					<hr />
					<div className="d-flex flex-row">
						<div className="px-2">
							<ProfileImage size="75" userInfo={actingAsState}/>
						</div>
						<div className="px-2">
							<div className="d-flex flex-row">
								{actingAsState.firstName} {actingAsState.lastName} {actingAsState.role !== 'User' ? `(${actingAsState.role})` : ''}
							</div>
							<div className="d-flex flex-row">
								{actingAsState.company}
							</div>
							<div className="d-flex flex-row">
								{actingAsState.email}
							</div>
							<div className="d-flex flex-row">
								{formatPhoneNumber(actingAsState.phone)}
							</div>
						</div>
					</div>
					<hr />
					<ProfileMenu userInfo={actingAsState} onClick={handleClose}/>
				</Container>

				<div className="position-absolute bottom-0 w-100 text-center p-3 bg-white"><a style={{cursor: 'pointer'}} onClick={handleLogout}>Logout</a></div>
			</Offcanvas>
		);
	}

	return (
		<>
			<Navbar bg="white" expand="lg" className="py-3 border-bottom border-bottom-3" style={{height:'60px'}}>
				<Container fluid={true}>
					<Navbar.Brand as={Link} to="/">
						<img className="d-block mx-auto" style={{ 'height': '30px' }} src="/assets/img/toolbox-logo.png" alt="BAY Logo" />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav>
							{/* <Nav.Item>
								<Nav.Link as={Link} to="/projects" className={useClass('projects')}>Projects</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link as={Link} to="/templates" className={useClass('templates')}>Templates</Nav.Link>
							</Nav.Item> */}
						</Nav>

						{/* <span className="ms-auto">
							<Nav.Item as={Link} to="/settings" className="text-secondary">
								<FiSettings/>
							</Nav.Item>
						</span> */}

						<span className="ms-auto" onClick={handleShow}>
							<ProfileImage userInfo={actingAsState}/>
						</span>
					</Navbar.Collapse>
				</Container>
			</Navbar>

			<UserProfile userInfo={actingAsState} />
		</>
	);
};

