import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { PageContainer } from '../partials/page/PageContainer';
import { MainNav } from '../partials/navigation/MainNav';
import { PageHeader } from '../partials/headers/PageHeader';

import globalState from '../../state/global';
import api from 'modules/api';
import StatsSection from './commonComponents/statsSection';
import { HOME_STAT_TITLES } from 'components/Constants/homeConstants';

export const Index = (props) => {
	const actingAsState = globalState.useActingAsState();
	const HomeState = globalState.useHomeState();
	const globalDispatch = globalState.useDispatch();

	useEffect(() => {
		(async () => {
			let keys = ['nTot', 'ofCustomers', 'ofCustomerTransaction']
			const resp = await Promise.all([api.mls.getMlsStats(), api.serviceManager.homeStats(), api.serviceManager.homeStats(null, 'transactions=true')]);
			const resultObject = keys.reduce((obj, key, index) => {
				typeof (resp[index]) === 'object' ? obj[key] = resp[index].nTot : obj[key] = resp[index]
				return obj;
			}, {});
			globalDispatch(globalState.setMainStats(resultObject));
		})();

	}, []);

	return (
		<>
			<PageContainer id="index">
				<Row className='m-0'>
					<Col sm={2} className="bg-gray-200" style={{ minHeight: 'calc(100vh - 60px)' }}>
						<MainNav />
					</Col>
					<Col sm={10} className="p-0 bg-gray-100" style={{ minHeight: 'calc(100vh - 60px)' }}>
						<Container className="pt-3 mb-5" fluid={true}>
							<PageHeader title={'Welcome ' + actingAsState.firstName} />
							<StatsSection statsList={HomeState} statsTitles={HOME_STAT_TITLES} />
						</Container>

					</Col>
				</Row>
			</PageContainer>
		</>
	);
};