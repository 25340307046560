import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CloseButton, Spinner } from 'react-bootstrap';
import { isNil } from 'lodash';
import { kaReducer } from 'ka-table';
import {
	ActionType,
	DataType,
	SortDirection,
} from 'ka-table/enums';
import 'react-toastify/dist/ReactToastify.css';

import BayHeader from '@back-at-you-inc/bay-ui/dist/BayHeader';
import BayFilterDrawer from '@back-at-you-inc/bay-ui/dist/BayFilterDrawer';
import BayModal from '@back-at-you-inc/bay-ui/dist/BayModal';
import BaySelect from '@back-at-you-inc/bay-ui/dist/BaySelect';
import BaySpinner from '@back-at-you-inc/bay-ui/dist/BaySpinner';

import api from '../../modules/api';
import { BAY_TOAST } from './ToastComponent';
import { PageContainer } from '../partials/page/PageContainer';
import { Loading } from '../partials/loading/Loading';
import { MainNav } from '../partials/navigation/MainNav';
import DataGridActionsBar from './services/UIComponents/dataGridActionsBar';
import ServiceFilterForm from './services/UIComponents/serviceFilterForm';
import ServicesTable from './services/UIComponents/servicesTable';
import ServiceQuickActions from './services/UIComponents/quickActions';

const tablePropsInit = {
	columns: [
		{ key: "selection-cell", isEditable: false, style: { width: 50, zIndex: 10, position: 'sticky', left: 0, top: 0, height: 54, borderRight: 0 } },
		{ key: 'name', style: { width: 250, zIndex: 10, position: 'sticky', left: 50, top: 0, paddingRight: 0 }, title: 'NAME', isSortable: true, dataType: DataType.String, sortDirection: SortDirection.Ascend },
		{ key: 'health', style: { width: 150, }, title: 'HEALTH', dataType: DataType.String, isSortable: true },
		{ key: 'status', style: { width: 150 }, title: 'STATUS', dataType: DataType.String, isSortable: true },
		{ key: 'action', style: { width: 150 }, title: 'ACTION', dataType: DataType.String },
		{ key: 'actionStatus', style: { width: 150 }, title: 'ACTION STATUS', dataType: DataType.String, isSortable: true },
		{ key: 'serviceName', style: { width: 250 }, title: 'SERVICE NAME', dataType: DataType.String, isSortable: true },
		{ key: 'host', style: { width: 300 }, title: 'HOST', dataType: DataType.String, isSortable: true },
		{ key: 'image', style: { width: 300 }, title: 'IMAGE', dataType: DataType.String, isSortable: true },
	],
	data: [],
	rowKeyField: '_id',
	selectedRows: []
}

export const Services = () => {

	const [filterTableProps, changeFilterTableProps] = useState(tablePropsInit);
	const [tableProps, changeTableProps] = useState(tablePropsInit);
	const [serviceStats, setServiceStats] = useState();
	const [services, setServices] = useState();
	const [filterDrawerData, setFilterDrawerData] = useState({});
	const [loading, setLoading] = useState(false);
	const [listLoading, setListLoading] = useState(false);
	const [statsLoading, setStatsLoading] = useState(false);
	const [disableLoading, setDisableLoading] = useState(false);
	const [showFilterDrawer, setShowFilterDrawer] = useState(false);
	const [enableActionModal, setEnableActionModal] = useState(false);
	const [selectedAction, setSelectedAction] = useState({});
	const [mainFilterData, setMainFilterData] = useState({});
	const [hostLists, setHostLists] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [modalHeader, setModalHeader] = useState("");
	const [selectedMoveId, setSelectedMoveId] = useState("");
	const [searchImageUrl, setSearchImageUrl] = useState("");
	const [filterCount, setFilterCount] = useState(0);

	const serviceStatsTitles = {
		totalHostCount: "TOTAL HOSTS",
		totalServiceCount: "TOTAL SERVICES",
		totalServiceDownCount: "SERVICES (DOWN)",
		totalServiceUpCount: "SERVICES (UP)",
	}
	const filteredTableProps = useMemo(() => {
		if (!searchValue) {
			return tableProps?.data
		} else {
			const filteredData = tableProps?.data?.filter(item =>
				item.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
				item.serviceName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
				item.action?.toLowerCase().includes(searchValue?.toLowerCase()) ||
				item.health?.toLowerCase().includes(searchValue?.toLowerCase()) ||
				item.actionStatus?.toLowerCase().includes(searchValue?.toLowerCase()) ||
				item.status?.toLowerCase().includes(searchValue?.toLowerCase())
			);
			return filteredData
		}
	}, [searchValue, tableProps])

	useEffect(() => {
		setLoading(true);
		setStatsLoading(true);
		api.serviceManager.stats()
			.then((res) => {
				setServiceStats(res);
			})
			.catch((err) => {
				console.log(err);
			}).finally(() => {
				setStatsLoading(false)
			});

		getServiceList()
		// dispatch(FETCH_STATS());
	}, [])
	useEffect(() => {
		if ((selectedAction?.eventType === 'Move') || selectedAction?.eventType === 'Update' || selectedAction?.eventType === 'Remove') {
			setModalHeader(`${selectedAction?.eventType} ${selectedAction?.wholeRow?.name}`)
			if ((selectedAction?.eventType === 'Move')) {
				setDisableLoading(true);
				api.serviceManager.distinctHosts()
					.then((res) => {
						const data = res?.distinctHosts?.filter((val) => !val.includes(selectedAction?.wholeRow?.host))
							.map((item) => {
								return {
									label: item,
									value: item
								}
							})
						setHostLists(data);
						setSelectedMoveId(data[0])
					})
					.catch((err) => {
						console.log(err);
					}).finally(() => {
						setDisableLoading(false);
					});
			}
		} else {
			setModalHeader('')
		}
		/* for modal header and for triggering host endpoint */
	}, [selectedAction])
	const getServiceList = () => {
		setListLoading(true)
		api.serviceManager.read()
			.then((res) => {
				setServices(res.data);
				changeTableProps((prev) => ({ ...prev, data: res.data, isDataSet: true }))
				changeFilterTableProps((prev) => ({ ...prev, data: res.data, isDataSet: true }))
				setLoading(false)

			})
			.catch((err) => {
				console.log(err);
			}).finally(() => {
				setListLoading(false)
			});
	}
	const loopFilterData = (arr, item) => {
		const keys = ['health', 'image', 'status', 'host', 'serviceName', 'name']
		return keys.every((val) => (!arr[val]?.length || arr[val]?.includes('all') || arr[val]?.includes(item[val].toLowerCase())))
		/* to filter the drawer values */
	}

	const drawerFilterData = useMemo(() => {
		return filterTableProps?.data?.filter((item) => {
			return loopFilterData(filterDrawerData, item)
		})
	}, [filterDrawerData])

	useEffect(() => {
		changeTableProps((prev) => ({ ...prev, data: drawerFilterData }));
		handleFilterDrawerClose();
	}, [drawerFilterData])


	useEffect(() => {
		let count = 0;
		for (const appliedFilter of Object.values(filterDrawerData)) {
			if (appliedFilter.length) {
				count++;
			}
		}
		setFilterCount(count)
	}, [filterDrawerData])

	const dispatch = async (action) => {
		// let settingsWithoutDataObject = {};

		changeTableProps((prevState) => {
			const newState = kaReducer(prevState, action);
			// const { data, ...settingsWithoutData } = newState;

			// settingsWithoutDataObject = {
			// 	...settingsWithoutData,
			// }
			return newState;
		});
		switch (action.type) {
			case ActionType.HideColumn:
			case ActionType.ShowColumn:
			case ActionType.ReorderColumns:
				// let tableColumns = '';
				// let slicedColumns = settingsWithoutDataObject?.columns?.slice(1);
				if (
					action?.targetColumnKey === 'selection-cell'
					|| action?.targetColumnKey === 'name'
					|| action?.columnKey === 'selection-cell'
					|| action?.columnKey === 'name'
				) {
					//We need to restrict the drag and drop for selection-cell and name
					changeTableProps((prevState) => ({ ...prevState, columns: tableProps?.columns }))
					return
				}
				/* Need to integrate with the update endpoint */

				// switch (action.type) {
				// 	case ActionType.HideColumn:
				// 		tableColumns = slicedColumns.filter((e) => {
				// 			return e?.visible !== false;
				// 		}).map((e) => {
				// 			return e.title
				// 		}).join(',')
				// 		break;

				// 	case ActionType.ShowColumn:
				// 		tableColumns = slicedColumns.filter((e) => {
				// 			return e?.visible !== false;
				// 		}).map((e) => {
				// 			return e.title
				// 		}).join(',')
				// 		break;
				// 	case ActionType.ReorderColumns:
				// 		tableColumns = slicedColumns.filter((e) => {
				// 			return e?.visible !== false;
				// 		}).map((e) => {
				// 			return e.title
				// 		}).join(',')
				// 		break;
				// 	default:
				// 		break;
				// }
				break;
			default:
				break;
		}
	};

	const handleModalText = () => {
		let modalText = selectedAction?.eventType === "Acknowledgement" ? "Acknowledge Issue" : selectedAction?.eventType;
		switch (selectedAction?.eventType) {
			case "Start":
			case "Stop":
			case "Rebuild":
			case "Remove":
			case "Acknowledgement":
				return <>
					<div>
						Are you sure you want to {modalText} this service?
					</div>
				</>
			case "Move":
				return <>
					{loading ? <Loading message="Loading" /> : <div>
						Move container to: <div className='mt-3'>
							<BaySelect
								onChangeFunc={(e) => setSelectedMoveId(e)}
								name='health'
								allowSelectAll={false}
								isClearable
								optionsArray={hostLists}
								defaultValue={selectedMoveId} />
						</div>
					</div>}
				</>
			case "Update":
				return <>
					<div className='custom-modal-content'>
						<label className='modal-label'>Update image to:</label>
						<div className="col-12 col-sm-12 col-md-12 col-lg-11 d-flex align-items-center justify-content-end">
							<div className="services-search-container">
								<input
									className="search-input"
									placeholder='Search...'
									onChange={(e) => {
										setSearchImageUrl(e.target.value)
									}}
								/>

							</div>
						</div>
					</div>
				</>
			default:
				return <></>
		}
	}

	const handleBulkActions = (type) => {
		setDisableLoading(true);
		const bodyFormData = new FormData();
		let quickActionData = {}
		const selectedType = type?.eventType
		const selectedID = selectedAction?.type === "individual" ? selectedAction?.selectedActionRow : tableProps?.selectedRows

		/* Handled to control quick action and bulk action via same function*/

		switch (selectedType) {
			case "Start":
				quickActionData = {
					action: 'start', ids: selectedID, actionSucessKey: 'Started', actionFailedKey: 'Starting'
				};
				break;
			case "Stop":
				quickActionData = {
					action: 'stop', ids: selectedID, actionSucessKey: 'Stopped', actionFailedKey: 'Stopping'
				}
				break;
			case "Rebuild":
				quickActionData = {
					action: 'rebuild', ids: selectedID, actionSucessKey: 'Rebuild', actionFailedKey: 'Rebuilding'
				}
				break;
			case "Move":
				quickActionData = {
					action: 'move', ids: selectedID, actionSucessKey: 'Moved', actionFailedKey: 'Moving'
				}
				break;
			case "Update":
				quickActionData = {
					action: 'update', ids: selectedID, actionSucessKey: 'Updated', actionFailedKey: 'Updating'
				}
				break;
			case "Remove":
				quickActionData = {
					action: 'remove', ids: selectedID, actionSucessKey: 'Removed', actionFailedKey: 'Removing'
				}
				break;
			case "Acknowledgement":
				quickActionData = {
					action: 'acknowledge', ids: selectedID, actionSucessKey: 'Acknowledge Issue', actionFailedKey: 'Acknowledging Issue'
				}
				break;

			default:
				break;
		}
		bodyFormData.append('action', quickActionData?.action);
		bodyFormData.append('ids', quickActionData?.ids);
		let successMSG = `Service Successfully ${quickActionData.actionSucessKey}`
		if (selectedType === 'Move') {
			quickActionData = { ...quickActionData, 'newHost': selectedMoveId?.value }
			bodyFormData.append('newHost', selectedMoveId?.value);
		}
		if (selectedType === 'Update') {
			quickActionData = { ...quickActionData, 'newImage': selectedMoveId?.value }
			bodyFormData.append('newImage', searchImageUrl);
			successMSG = "Service Image URL Successfully Updated"
		}
		api.serviceManager.quickActions(bodyFormData)
			.then((res) => {
				BAY_TOAST('success', successMSG);
				getServiceList()
			})
			.catch((err) => {
				BAY_TOAST('error', `Failed ${quickActionData.actionFailedKey} Service `);
				console.log(err);
			}).finally(() => {
				setEnableActionModal(false);
				setDisableLoading(false);
			});
	}

	const clearDrawerFilter = () => {
		changeTableProps({ ...tableProps, data: filterTableProps?.data })
		setShowFilterDrawer(false)
		setMainFilterData({})
		setFilterCount(0)
	}

	const FilterDrawerHeader = ({ handleCloseDrawer }) => {
		return (
			<div className="d-flex flex-column w-100 filter-drawer-header">
				<div className="d-flex align-item-center justify-content-between">
					<div className="d-flex align-items-center">
						<h2 className="filter-header">Filter</h2>
					</div>
					<div className="d-flex align-items-center">
						<CloseButton
							className="me-0"
							onClick={handleCloseDrawer}
						/>
					</div>
				</div>
			</div>
		);
	};

	const handleFilterDrawerClose = () => {
		setShowFilterDrawer(false);
	};
	return (
		<div class="services-page">
			<PageContainer id="index">
				<Row className='m-0'>
					<Col sm={2} className="bg-gray-200" style={{ minHeight: 'calc(100vh - 60px)' }}>
						<MainNav />
					</Col>
					<Col sm={10} className="p-0 bg-gray-100" style={{ minHeight: 'calc(100vh - 60px)' }}>
						<Container className="pt-3" fluid={true}>
							<BayHeader
								headerContent={'Here you can review the different services available and their current status. You are able to modify each service from this interface as well.'}
								headerTitle={'Services'}
							/>
							{/* <PageHeader title="Services"> */}
							{/* <Stack className="align-self-center" direction="horizontal" gap={3}>
								{
									getAccess().isSuperAdmin || getAccess().isSystemAdmin
									? <div className="ms-auto"><Button variant="primary" size="sm" onClick={() => handleCreate()}><FiPlus /> Create User</Button></div>
									: null
								}
							</Stack> */}
							{/* </PageHeader> */}
						</Container>
						<Container className="pt-3" fluid={true}>
							{isNil(serviceStats) || statsLoading
								? (
									<Loading message="Loading" />
								)
								: (
									<Row className='d-flex'>
										{Object.keys(serviceStats).map((item, index) => (
											<Col key={item} xs={12} sm={6} md={6} lg={4} xl={3} >
												<Card>
													<Card.Header>{serviceStatsTitles[item]}</Card.Header>
													<Card.Body>
														<Card.Text>
															<h1 className="mb-0">{serviceStats[item]}</h1>
														</Card.Text>
													</Card.Body>
												</Card>
											</Col>
										))}
									</Row>
								)
							}
						</Container>
						<Container fluid={true}>
							<DataGridActionsBar
								dispatch={dispatch}
								loading={loading}
								tableProps={tableProps}
								SetEnableFilterDrawer={setShowFilterDrawer}
								setSearchValue={setSearchValue}
								handleBulkActions={handleBulkActions}
								setSelectedAction={setSelectedAction}
								setEnableActionModal={setEnableActionModal}
								filterCount={filterCount}
								selectedAction={selectedAction}
							/>
						</Container>
						<Container className="pt-2 pb-4" fluid={true}>
							<div className='services-table'>
								<div className='bay-data-grid'>
									<div className="d-none d-sm-block">

										<ServicesTable
											tableProps={{ ...tableProps, data: filteredTableProps }}
											dispatch={dispatch}
											loading={loading}
											listLoad={listLoading}
											services={services}
											selectedAction={selectedAction}
											setSelectedAction={setSelectedAction}
											handleBulkActions={handleBulkActions}
											setEnableActionModal={setEnableActionModal}
										/>
									</div>
									<div className="d-block d-sm-none row g-0">
										<div className={`${loading ? 'bay-placeholder-overlay' : ''}`}>
											{loading && (filteredTableProps && filteredTableProps?.length) && <BaySpinner title={'Loading'} className="w-100 h-100 position-absolute" />}
											{
												filteredTableProps?.length ? filteredTableProps?.map((serviceDetails, index) => {
													return (
														<div
															className="col xs-data-grid ps-2 cursor-pointer"
															key={index}>
															<div className="d-flex align-items-center w-100">
																<div className='d-flex'
																	style={{ width: '88%' }} // Width speicfically set for 560 to 320 mis-alignment,Dropdown event click and purpose of Dropdown position
																>
																	<div className="content ms-3 w-75">
																		<div className="service-name text-dark text-truncate mx-0 text-capitalize"> {serviceDetails.name} </div>
																	</div>
																</div>
																<>
																	<div className="d-flex">
																		<div className="px-2 d-flex align-items-center dot">
																			<div className="col-action-button text-primary cursor-pointer dot py-1">
																				<ServiceQuickActions
																					setSelectedAction={setSelectedAction}
																					setEnableActionModal={setEnableActionModal}
																					rowData={serviceDetails}
																					dispatch={dispatch}
																				/>
																			</div>
																		</div>
																	</div>
																</>
															</div>

														</div>
													);
												}) : <span className='d-flex justify-content-center'>No Data Found</span>
											}
										</div>
									</div>
								</div>
							</div>
						</Container>
						{/* load more functions need to integrate with update endpoint */}
						<Container className="pt-2" fluid={true}>
							<div className="d-flex align-items-center justify-content-between py-3 pe-1">
								{/* <PageSizeSelector sizeArray={pageSizes} pageSize={pageSize} />
								<Button
									variant="outline-secondary"
								>
									Load More
								</Button> */}
								<span className='d-flex'>
									Total <span className='d-none d-sm-block ms-1'>results </span> : {filteredTableProps?.length || 0}
								</span>
							</div>
						</Container>
						<BayFilterDrawer
							showDrawer={showFilterDrawer}
							setShowFilterDrawer={setShowFilterDrawer}
							handleCloseDrawer={() => handleFilterDrawerClose()}
							headerChildren={
								<FilterDrawerHeader
									handleCloseDrawer={() => handleFilterDrawerClose()}
								/>
							}
						>
							<ServiceFilterForm
								mainFilterData={mainFilterData}
								setMainFilterData={setMainFilterData}
								setFilterDrawerData={setFilterDrawerData}
								tableProps={tableProps}
								filterDrawerData={filterDrawerData}
								clearDrawerFilter={clearDrawerFilter}
								services={services} />
						</BayFilterDrawer>
					</Col>
				</Row>
			</PageContainer>
			{enableActionModal &&
				<>
					<BayModal
						disabled={disableLoading || (!searchImageUrl?.length && selectedAction?.eventType === "Update")}
						buttonText={
							selectedAction?.eventType === "Move"
								? 'Move'
								: selectedAction?.eventType === "Update"
									? "Update"
									: "Yes"
						}
						cancelText="Close"
						submitFunc={() => handleBulkActions(selectedAction)}
						onHideFunc={() => setEnableActionModal(false)}
						closeButton={true}
						title={modalHeader?.length && selectedAction?.type !== 'bulk' ? modalHeader : ''}
						hideHeader={!modalHeader?.length}
						show={enableActionModal}
					>
						<Row className='gy-2'>
							<Col xs={12}>
								{disableLoading && <div className='h-100 w-100 d-flex justify-content-center position-absolute'>
									<Spinner animation="border" role="status"></Spinner>
								</div>}
								{handleModalText()}
							</Col>
						</Row>
					</BayModal></>
			}
		</div>
	)
}
