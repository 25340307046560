import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';

import globalState from './state/global';
import { App } from './components/App';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	// <React.StrictMode>
	<Provider store={globalState.store}>
		<BrowserRouter>
			<App />
			<ToastContainer />
		</BrowserRouter>
	</Provider>
	// </React.StrictMode>
);


