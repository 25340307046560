import { isNil } from "lodash";
import React from 'react';
import { Form, Card } from 'react-bootstrap';

export const applications = ['Account', 'Design', 'Developer', 'Toolbox', 'Internal'];

export const applicationRoles = {
	'Account': {
		options:{
			multi: false
		},
		roles: [
			{role: 'sys', label: 'System Admin'}
			, {role: 'superadmin', label: 'Super Admin'}
			, {role: 'superuser', label: 'SuperUser'}
			, {role: 'admin', label: 'Admin'}
			, {role: 'staff', label: 'Staff'}
			, {role: 'user', label: 'User'}
		]
	},
	'Design':  {
		options:{
			multi: false
		},
		roles: [
			{role: 'sys', label: 'System Admin'}
			, {role: 'superadmin', label: 'Super Admin'}
			, {role: 'superuser', label: 'SuperUser'}
			, {role: 'admin', label: 'Admin'}
			, {role: 'staff', label: 'Staff'}
			, {role: 'user', label: 'User'}
		]
	},
	'Developer':  {
		options:{
			multi: false
		},
		roles:  [
			{role: 'sys', label: 'System Admin'}
			, {role: 'superadmin', label: 'Super Admin'}
			, {role: 'superuser', label: 'Super User'}
			, {role: 'user', label: 'User'}
		]
	},
	'Toolbox':  {
		options:{
			multi: false
		},
		roles:  [
			{role: 'sys', label: 'System Admin'}
			, {role: 'superadmin', label: 'Super Admin'}
			, {role: 'superuser', label: 'Super User'}
		]
	},
	'Internal':  {
		options:{
			multi: true
		},
		roles:  [
			{role: 'admin', label: 'Admin'}
			, {role: 'billing', label: 'Billing'}
			, {role: 'client_success', label: 'Client Success'}
			, {role: 'client_support', label: 'Client Support'}
			, {role: 'coach', label: 'Coach'}
			, {role: 'content', label: 'Content'}
			, {role: 'design', label: 'Design'}
			, {role: 'enterprise_client_success', label: 'Enterprise Client Success'}
			, {role: 'marketing', label: 'Marketing'}
			, {role: 'sales', label: 'Sales'}
			, {role: 'sales_enterprise', label: 'Sales Enterprise'}
			, {role: 'sales_manager', label: 'Sales Manager'}
			, {role: 'user', label: 'User'}
		]
	}
};


export const Roles = (props) => {
	const onChange = (e) => {
		//checks and radios have different targets
		let target = e.currentTarget || e.target;

		let roles = props.roles;

		if (isNil(roles[target.name])) {
			roles[target.name] = [];
		}

		if(target.value === '') {
			roles[target.name] = [];
		} else {
			if(target.type === 'radio') {
				roles[target.name] = [target.value];
			} else {
				if (target.checked) {
					roles[target.name].push(target.value);
				} else {
					roles[target.name] = roles[target.name].filter((item) => item !== target.value);
				}
			}
		}

		const eventData = {
			target: {
				name: 'purview'
				, value: roles
			}
		}

		props.onChange(eventData);

		return;
	};

	return (
		<>
			<Card className="mb-3">
				<Card.Header>
					<Card.Title className="mb-0">Roles</Card.Title>
				</Card.Header>
				<Card.Body>
					{applications.map((app, index) => (
						<Form.Group className="mb-3" key={index}>
							<Form.Label className="fw-bold">{app}:</Form.Label>
							<Form.Check className="ms-3 mb-3" id={`role-${app.toLowerCase()}-none`} key={'none'} type={applicationRoles[app].options.multi ? 'checkbox' : 'radio'} name={app} value="" label="No Access" onChange={onChange} checked={(!props.roles[app] || props.roles[app].indexOf('none') !== -1 || props.roles[app].length === 0 ? true : false)} />
							{applicationRoles[app].roles.map((item, index) => (
								<Form.Check className="ms-3" id={`role-${app.toLowerCase()}-${index}`} key={index} type={applicationRoles[app].options.multi ? 'checkbox' : 'radio'} name={app} value={item.role} label={item.label} onChange={onChange} checked={((props.roles[app] && props.roles[app].indexOf(item.role) !== -1) ? true : false)} />
							))}
						</Form.Group>
					))}
				</Card.Body>
			</Card>
		</>
	);
};
