import React from 'react';
import { Badge, Button } from 'react-bootstrap';

import FilterIcon from '@back-at-you-inc/bay-ui/dist/Icons/FilterIcon';

import CustomIcon from 'components/Icons';
import VisibilityColumnComponent from './visibilityComponent';
import BulkActions from './bulkActions';

const DataGridActionsBar = ({
    SetEnableFilterDrawer,
    tableProps,
    loading,
    dispatch,
    showSearch = true,
    setSearchValue,
    handleBulkActions,
    setSelectedAction,
    setEnableActionModal,
    filterCount
}) => {

    return (
        <div className="bay-datagrid-bar my-3 pt-4 border-top">
            {tableProps?.selectedRows?.length ? < BulkActions
                setSelectedAction={setSelectedAction}
                tableProps={tableProps}
                handleBulkActions={handleBulkActions}
                setEnableActionModal={setEnableActionModal}
            /> :
                <div className="row g-0 justify-content-between">
                    <div className="col-6 col-sm-4 d-none d-sm-flex align-items-center">
                        <div className="row g-0">
                            <div className="col-md-auto col-sm-4  d-flex">
                                <Button
                                    onClick={() => SetEnableFilterDrawer(true)}
                                    variant="outline-secondary"
                                    className="filter-btn d-flex align-items-center me-2 position-relative"
                                >
                                    {filterCount > 0 && <Badge className="fiter-badge" bg='primary'>{filterCount}</Badge>}
                                    <FilterIcon className="me-2" />
                                    <span className="d-none d-md-inline">
                                        Filter
                                    </span>
                                </Button>
                                <VisibilityColumnComponent tableProps={tableProps} loading={loading} dispatch={dispatch} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-8">
                        <div className="row g-0 justify-content-between justify-content-sm-end">
                            {showSearch &&
                                <div className="col-12 col-sm-8 d-flex align-items-center justify-content-end">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-flex align-items-center justify-content-end">
                                        <div className="services-search-container">
                                            <div className="search-icon">
                                                <CustomIcon icon="search" size={16} />
                                            </div>
                                            <input
                                                className="search-input"
                                                placeholder='Search...'
                                                onChange={(e) => {
                                                    setSearchValue(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>}
        </div>
    );
};

export default DataGridActionsBar;
