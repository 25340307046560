import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Stack, Navbar, Nav, Button, Offcanvas, ToggleButtonGroup, ToggleButton, ButtonGroup, Modal, Form, Table, Card, FormCheck } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { isNil, cloneDeep, clone, uniqBy } from 'lodash';

import { PageContainer } from '../partials/page/PageContainer';
import { MainNav } from '../partials/navigation/MainNav';
import { PageHeader } from '../partials/headers/PageHeader';
import { Loading } from '../partials/loading/Loading';

import { FiPlus, FiEdit, FiTrash } from 'react-icons/fi';

import { Brand } from "../partials/input/Brand";
import { Addresses } from "../partials/input/Addresses";
import { PhoneNumbers } from "../partials/input/PhoneNumbers";
import { getAccess } from "../../modules/access";

import api from '../../modules/api';

export const Customers = (props) => {
	const [customers, setCustomers] = useState();
	const [customer, setCustomer] = useState();

	const [customerLoading, setCustomerLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		handleRead();
	}, []);


	const handleRead = async (customerId) => {
		setCustomers();

		api.customers.read(customerId)
			.then(resp => {
				// console.log('readResult', resp);

				if(resp.status === 0){
					setCustomers(resp.result);
				}else{
					console.error(resp.message);
					setCustomers([]);
				}
			})
			.catch(e => {
				console.error(e.message);

				setCustomers([]);
			});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		setIsSaving(true);

		try {
			console.log(customer);
			setIsSaving(false);
			setCustomer();
		} catch (error) {
			setIsSaving(false);
			setCustomer();
			console.error('Error updating customer:', error);
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;

		console.log(name, value);

		setCustomer((prevCustomer) => ({
			...prevCustomer,
			[name]: value,
		}));
	};

	const handleDelete = async (id) => {
		try {
			setCustomer({});
		} catch (error) {
			console.error('Error deleting customer:', error);
			setCustomer();
		}
	};

	const handleCreate = async () => {
		try {
			setCustomer({});
		} catch (error) {
			console.error('Error creating customer:', error);
			setCustomer();
		}
	};

	const handleSelect = async (customer) => {
		try {
			setCustomer(customer);
		} catch (error) {
			console.error('Error updating customer:', error);
			setCustomer();
		}
	};

	return (
		<>
		<PageContainer id="index">
			<Row className='m-0'>
				<Col sm={2} className="bg-gray-200" style={{minHeight: 'calc(100vh - 60px)'}}>
					<MainNav />
				</Col>
				<Col sm={10} className="p-0 bg-gray-100" style={{minHeight: 'calc(100vh - 60px)'}}>
					<Container className="pt-3" fluid={true}>
						<PageHeader title="Customers">
							<Stack className="align-self-center" direction="horizontal" gap={3}>
								{
									getAccess().isSuperAdmin || getAccess().isSystemAdmin
									? <div className="ms-auto"><Button variant="primary" size="sm" onClick={() => handleCreate()}><FiPlus /> Create Customer</Button></div>
									: null
								}
							</Stack>
						</PageHeader>
					</Container>
					<Container className="pt-3" fluid={true}>
						{isNil(customers)
						? (
							<Loading message="Loading Customers" />
						)
						: (
							<Table className="striped">
								<thead>
									<tr>
										<th>Name</th>
										<th>Display Name</th>
										<th>Contact Name</th>
										<th>Contact Email</th>
										<th>Contact Number</th>
										<th className="text-center">Action</th>
									</tr>
								</thead>
								<tbody>
									{customers.map((customer, index) => (
										<tr key={index}>
											<td>{customer.name}</td>
											<td>{customer.displayName}</td>
											<td>{customer.contact.firstName} {customer.contact.lastName}</td>
											<td>{customer.contact.email}</td>
											<td>{customer.contact.phone}</td>
											<td className="d-flex align-items-center justify-content-center">
												<Stack direction="horizontal" gap={1}>
													<Button variant="link" className="p-0" size="sm" onClick={() => handleSelect(customer)}><FiEdit /></Button>
													<Button variant="link" size="sm" onClick={() => handleDelete(customer)}><FiTrash /></Button>
												</Stack>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						)}
					</Container>
				</Col>
			</Row>
		</PageContainer>

		<Offcanvas show={!isNil(customer)} onHide={() => setCustomer()} placement="end" className="text-dark" backdrop={true} scroll={true}>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>Customer</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				{!isNil(customer)  && (
					<Form onSubmit={handleSubmit}>
						<Form.Group className="mb-3">
							<Form.Label>Customername:</Form.Label>
							<Form.Control type="text" name="customername" value={customer.customername || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Email:</Form.Label>
							<Form.Control type="email" name="email" value={customer.email || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>First Name:</Form.Label>
							<Form.Control type="text" name="firstName" value={customer.firstName || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Last Name:</Form.Label>
							<Form.Control type="text" name="lastName" value={customer.lastName || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Birth Date:</Form.Label>
							<Form.Control type="date" name="birthDate" value={customer.birthDate || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>License Number:</Form.Label>
							<Form.Control type="text" name="licenseNumber" value={customer.licenseNumber || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Certifications:</Form.Label>
							<Form.Control type="text" name="certifications" value={customer.certifications || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Website URL:</Form.Label>
							<Form.Control type="url" name="websiteUrl" value={customer.websiteUrl || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Company Name:</Form.Label>
							<Form.Control type="text" name="companyName" value={customer.companyName || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Time Zone:</Form.Label>
							<Form.Control type="text" name="timeZone" value={customer.timeZone || ''} onChange={handleChange} />
						</Form.Group>

						<Addresses onChange={handleChange} addresses={customer.addresses}/>

						<PhoneNumbers onChange={handleChange} phoneNumbers={customer.phoneNumbers}/>

						<Brand onChange={handleChange} brand={customer.brand}/>

						<Form.Group className="mb-3">
							<Button variant="primary" type="submit" disabled={customerLoading || isSaving}>Save</Button>
						</Form.Group>
					</Form>
				)}
			</Offcanvas.Body>
		</Offcanvas>
		</>
	);
};
