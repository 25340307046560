import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { isEmpty, isNil } from 'lodash';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";

import { Login } from './pages/login';
import { Index } from './pages/index';
import { Services } from './pages/services';
import { Customers } from './pages/customers';
import { Users } from './pages/users';

import globalState from '../state/global';

import api from "../modules/api";

var sessionExpiring = false;
var sessionExpired = false;
var expireInterval;

export const App = ()=>{
	const bayToken = Cookies.get('bayToken');
	const [showSessionExpiringModal, setShowSessionExpiringModal] = React.useState(false);

	const [showSessionExpiredModal, setShowSessionExpiredModal] = React.useState(false);

	const userState = globalState.useUserState();
	const notificationState = globalState.useNotificationState();

	const globalDispatch = globalState.useDispatch();

	if(!isNil(bayToken)){
		expireInterval = setInterval(()=>{
			const jwt = jwt_decode(bayToken);

			const now = new Date();
			const jwtRefesh = (jwt.expRefresh * 1000);

			//if the refresh token has expired already then log the user out
			sessionExpired = now.getTime() > jwtRefesh;

			//if the refresh token is less than 5 minutes away from expiring
			sessionExpiring = (jwtRefesh - now.getTime()) <= 300000;

			console.log('sessionExpired', sessionExpired);
			console.log('sessionExpiring', sessionExpiring);

			if(sessionExpired){
				setShowSessionExpiringModal(false);
				setShowSessionExpiredModal(true);
			}

			if(sessionExpiring){
				setShowSessionExpiringModal(true);
			}
		}, 5000);
	}

	useEffect(()=>{
		if(sessionExpired){
			clearInterval(expireInterval);
			setShowSessionExpiredModal(true);
		}
	}, [sessionExpired]);


	const handleSessionExpiredModalClose = ()=>{
		logout();
	}

	const logout = ()=>{
		sessionStorage.clear();
		Cookies.remove('bayToken');
		window.location.href = '/';
	}

	const handleSessionExpiringModalClose = ()=>{
		api.login.refresh()
			.then(resp => {
				console.log('refreshResult', resp);

				if(resp.status === 0){
					setShowSessionExpiringModal(false);
				}else{
					logout();
				}
			})
			.catch(e => {
				logout();
			});
	}


	if(isNil(userState) || isEmpty(userState) || isNil(Cookies.get('bayToken'))){
		return (
			<Login />
		);
	}else{
		return (
			<>
				<Routes>
					<Route path="/" element={<Index />} />
					<Route path="/services" element={<Services />} />

					<Route path="/customers" element={<Customers />} />
					<Route path="/users" element={<Users />} />
				</Routes>


				{/* global notifications modal */}
				{!isNil(notificationState) && (
					<Modal show={notificationState.show} size={notificationState.size} backdrop={notificationState.backdrop}>
						<Modal.Header>
							<Modal.Title>{notificationState.title}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{notificationState.body}
						</Modal.Body>
						<Modal.Footer>
							{notificationState.redirectOnOk && (
								<Button variant="danger" onClick={()=>globalDispatch(globalState.resetNotificationState())}>Ok</Button>
							)}
						</Modal.Footer>
					</Modal>
				)}

				{/* global notifications modal */}
				{showSessionExpiredModal && (
					<Modal show={showSessionExpiredModal} backdrop={true}>
						<Modal.Header>
							<Modal.Title>Session Expired</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Your session has expired. You will be redirected to the login page, to re-login.
						</Modal.Body>
						<Modal.Footer>
							<Button variant="danger" onClick={()=>handleSessionExpiredModalClose()}>Ok</Button>
						</Modal.Footer>
					</Modal>
				)}

				{/* global notifications modal */}
				{showSessionExpiringModal && (
					<Modal show={showSessionExpiringModal} backdrop={true}>
						<Modal.Header>
							<Modal.Title>Session Expiring Soon</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Your session is about expired, please click Ok to continue your session.
						</Modal.Body>
						<Modal.Footer>
							<Button variant="success" onClick={()=>handleSessionExpiringModalClose()}>Ok</Button>
						</Modal.Footer>
					</Modal>
				)}
			</>
		);
	}
};
