import React from 'react';
import { Nav } from 'react-bootstrap';

import { getAccess } from '../../../modules/access';

import globalState from '../../../state/global';

export const ProfileMenu = (props) => {
	const userInfo = props.userInfo;
	const globalDispatch = globalState.useDispatch();

	return (
		<Nav className="flex-column" onClick={props.onClick}>
			{
				(getAccess().isSuperAdmin || getAccess().isSystemAdmin)
				? <></>//<Nav.Link as={HashLink} to="/templates" className={(window.location.pathname.indexOf('/templates') !== -1 ? 'bg-light' : null)} style={(window.location.pathname.indexOf('/templates') ? {borderRadius: '0.475rem'} : null)}>Templates</Nav.Link>
				: null
				}
		</Nav>
	)
}
