import axios from 'axios';
import Cookies from 'js-cookie';
import { cloneDeep } from 'lodash';
import { TOOLBOX_API_URL } from '../../lib/environment';

export default {
	read: (componentId) => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');

			const controller = new AbortController();

			let timeout = setTimeout(()=>{
				controller.abort();
			}, 180000);

			axios({
				url: `${TOOLBOX_API_URL}/componentStates/ui-design:${componentId}`
				, method: 'GET'
				, cache: 'no-cache'
				// , headers: {
				// 	'Authorization': `Bearer ${bayToken}`
				// }
			})
			.then(resp => {
				clearTimeout(timeout);

				resolve(resp.data);
			})
			.catch(err => {
				reject(err);
			});
		});
	},

	update: (componentId, data) => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');

			const controller = new AbortController();

			let timeout = setTimeout(()=>{
				controller.abort();
			}, 180000);

			axios({
				url: `${TOOLBOX_API_URL}/componentStates/ui-design:${componentId}`,
				method: 'PUT',
				cache: 'no-cache',
				// headers: {
				// 	'Authorization': `Bearer ${bayToken}`
				// },
				data: {state: data},
				signal: controller.signal
			})
			.then((resp) => {
				clearTimeout(timeout);

				resolve(resp.data);
			})
			.catch((err) => {
				clearTimeout(timeout);

				reject(err);
			});
		});
	},

	delete: (componentId) => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');

			const controller = new AbortController();

			let timeout = setTimeout(()=>{
				controller.abort();
			}, 180000);

			axios({
				url: `${TOOLBOX_API_URL}/componentStates/ui-design:${componentId}`,
				method: 'DELETE',
				cache: 'no-cache',
				// headers: {
				// 	Authorization: `token ${bayToken}`
				// },
				signal: controller.signal
			})
			.then((resp) => {
				clearTimeout(timeout);

				resolve(resp.data);
			})
			.catch((err) => {
				clearTimeout(timeout);

				reject(err);
			});
		});
	}
};
