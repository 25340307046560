import React, { useEffect } from 'react';

import { FaSpinner } from 'react-icons/fa';

export const Loading = (props) => {
	return (
		<div className={props.className}>
			<FaSpinner className="spin" /> {props.message || 'Loading'}
		</div>
	);
};
