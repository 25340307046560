import React, { useState } from 'react';
import { Form, Card } from 'react-bootstrap';

export const PhoneNumbers = (props) => {
	const [phoneNumbers, setPhoneNumbers] = useState(props.phoneNumbers || [{
		number: ''
		, extension: ''
		, isDefault: true
	}]);

	const onChange = (e) => {
		let target = e.currentTarget || e.target;

		let p = {...phoneNumbers[0]};

		p[target.name] = target.value;

		setPhoneNumbers([p]);

		const eventData = {
			target: {
				name: 'phoneNumbers'
				, value: [p]
			}
		}

		props.onChange(eventData);

		return;
	};

	return (
		<>
			{phoneNumbers.map((app, index) => (
				<Card className="mb-3" key={index}>
					<Card.Header>
						<Card.Title className="mb-0">Phone Numbers</Card.Title>
					</Card.Header>
					<Card.Body>
						<Form.Group className="mb-3">
							<Form.Label>Phone Number:</Form.Label>
							<Form.Control type="text" name="number" value={phoneNumbers?.[0]?.number || ''} onChange={onChange} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Phone Extension:</Form.Label>
							<Form.Control type="text" name="extension" value={phoneNumbers?.[0]?.extension || ''} onChange={onChange} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Type:</Form.Label>
							<Form.Select aria-label="Default select example" value={phoneNumbers?.[0]?.type || ''} onChange={onChange}>
								{['Work', 'Home', 'Mobile', 'Fax', 'Other'].map((type, index) => (
									<option key={index} value={type.toLowerCase()}>{type}</option>
								))}
							</Form.Select>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Primary:</Form.Label>
							<Form.Select value={phoneNumbers?.[0]?.isPrimary || false} onChange={onChange}>
								{[true, false].map((primary, index) => (
									<option key={index} value={primary}>{primary ? 'Yes' : 'No'}</option>
								))}
							</Form.Select>
						</Form.Group>
					</Card.Body>
				</Card>
			))}
		</>
	);
};
