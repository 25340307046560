import { add, isNil } from "lodash";
import React, { useState } from 'react';
import { Form, Card, Row, Col } from 'react-bootstrap';

export const Brand = (props) => {
	const [brand, setBrand] = useState(props.brand || {
		primaryFont: 'Nunito Sans'
		, primaryColor: ''
		, primaryColorFontColor: ''
		, secondaryFont: 'Open Sans'
		, secondaryColor: ''
		, secondaryColorFontColor: ''
		, logoDark: ''
		, logoLight: ''
	});

	const onChange = (e) => {
		let target = e.currentTarget || e.target;

		let b = {...brand};

		b[target.name] = target.value;

		setBrand(b);

		const eventData = {
			target: {
				name: 'brand'
				, value: b
			}
		}

		props.onChange(eventData);

		return;
	};

	return (
		<Card className="mb-3">
			<Card.Header>
				<Card.Title className="mb-0">Brand</Card.Title>
			</Card.Header>
			<Card.Body>
				<Form.Group className="mb-3">
					<Form.Label>Primary Font:</Form.Label>
					<Form.Control type="text" name="primaryFont" value={brand?.primaryFont || 'Nunito Sans'} onChange={onChange} />
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Primary Color:</Form.Label>
					<Form.Control type="color" name="primaryColor" value={brand?.primaryColor || '#e043b1'} onChange={onChange} />
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Primary Color Font Color:</Form.Label>
					<Form.Control type="color" name="primaryColorFontColor" value={brand?.primaryColorFontColor || '#ffffff'} onChange={onChange} />
					<Form.Text muted>This color is used for text that overlays the primary color.</Form.Text>
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Secondary Font:</Form.Label>
					<Form.Control type="text" name="secondaryFont" value={brand?.secondaryFont || 'Open Sans'} onChange={onChange} />
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Secondary Color:</Form.Label>
					<Form.Control type="color" name="secondaryColor" value={brand?.secondaryColor || '#58595b'} onChange={onChange} />
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Secondary Color Font Color:</Form.Label>
					<Form.Control type="color" name="secondaryColorFontColor" value={brand?.secondaryColorFontColor || '#ffffff'} onChange={onChange} />
					<Form.Text muted>This color is used for text that overlays the secondary color.</Form.Text>
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Logo Dark:</Form.Label>
					<Form.Control type="text" name="logoDark" value={brand?.logoDark || ''} onChange={onChange} />
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Logo Light:</Form.Label>
					<Form.Control type="text" name="logoLight" value={brand?.logoLight || ''} onChange={onChange} />
				</Form.Group>
			</Card.Body>
		</Card>
	);
};
