import axios from 'axios';
import { TOOLBOX_API_URL } from '../../lib/environment';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	read: (data) => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');

			const controller = new AbortController();

			let timeout = setTimeout(() => {
				controller.abort();
			}, 180000);

			axios({
				url: `${TOOLBOX_API_URL}/service-manager`,
				method: 'GET',
				cache: 'no-cache',
				data: data
				, signal: controller.signal
			})
				.then((resp) => {
					clearTimeout(timeout);

					resolve(resp.data.result);
				})
				.catch((err) => {
					clearTimeout(timeout);

					reject(err);
				});
		});
	},
	stats: (data) => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');

			const controller = new AbortController();

			let timeout = setTimeout(() => {
				controller.abort();
			}, 180000);

			axios({
				url: `${TOOLBOX_API_URL}/service-manager/stats`,
				method: 'GET',
				cache: 'no-cache',
				data: data
				, signal: controller.signal
			})
				.then((resp) => {
					clearTimeout(timeout);

					resolve(resp.data.result);
				})
				.catch((err) => {
					clearTimeout(timeout);

					reject(err);
				});
		});
	},
	homeStats: (data, params = "") => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');
			axios({
				url: `${TOOLBOX_API_URL}/customers/stats${params?.length ? '?' + params : ''}`,
				method: 'GET',
				cache: 'no-cache',
				data: data,
				signal: AbortSignal.timeout(180000)
			})
				.then((resp) => {
					resolve(resp.data.result);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	distinctHosts: (data) => {
		return new Promise((resolve, reject) => {
			const controller = new AbortController();
			let timeout = setTimeout(() => {
				controller.abort();
			}, 180000);

			axios({
				url: `${TOOLBOX_API_URL}/service-manager/hosts`,
				method: 'GET',
				cache: 'no-cache',
				data: data
				, signal: controller.signal
			})
				.then((resp) => {
					clearTimeout(timeout);

					resolve(resp.data.result);
				})
				.catch((err) => {
					clearTimeout(timeout);

					reject(err);
				});
		});
	},
	quickActions: (data) => {
		const controller = new AbortController();
		return new Promise((resolve, reject) => {
			axios({
				url: `${TOOLBOX_API_URL}/service-manager`,
				method: 'POST',
				cache: 'no-cache',
				// headers: {
				// 	'Authorization': `Bearer ${bayToken}`
				// },
				data: data
				, signal: controller.signal
			})
				.then((resp) => {

					resolve(resp.data.result);
				})
				.catch((err) => {

					reject(err);
				});

		});
	},

};
