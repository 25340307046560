import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { FiHome, FiLayout, FiSettings, FiFileText, FiLock, FiUser, FiUsers } from 'react-icons/fi';

import { getAccess } from "../../../modules/access";

import globalState from '../../../state/global';

export const MainNav = (props) => {
	// const userState = globalState.useUserState();
	const actingAsState = globalState.useActingAsState();

	let navigate = useNavigate();

	const getClass = (pathName)=>{
		if(window.location.pathname === pathName){
			return 'text-dark text-decoration-none fw-bolder';
		}else{
			return 'text-dark text-decoration-none';
		}
	}

	return (
		<ul className="list-unstyled my-3">
			<li className="mb-1">
				<Button variant="link" className={getClass('/')} onClick={()=>{navigate('/')}}>
					<FiHome /> Home
				</Button>
			</li>
			<li className="mb-1">
				<Button variant="link" className={getClass('/services')} onClick={()=>{navigate('/services')}}>
					<FiLayout /> Services
				</Button>
			</li>
			{(getAccess().isSuperAdmin || getAccess().isSystemAdmin) && (
				<>
					<li className="mb-1 mt-5">
						<Button variant="link" className={getClass('/admin')}>
							<FiLock /> Admin
						</Button>
					</li>
					<li className="ms-3 mb-1">
						<Button variant="link" className={getClass('/users')} onClick={()=>{navigate('/users')}}>
							<FiUser /> Users
						</Button>
					</li>
					<li className="ms-3 mb-1">
						<Button variant="link" className={getClass('/customers')} onClick={()=>{navigate('/customers')}}>
							<FiUsers /> Customers
						</Button>
					</li>
				</>
			)}
		</ul>
	);
};
