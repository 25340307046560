import React, { useState, useRef, useEffect, useMemo } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Dropdown from 'react-bootstrap/Dropdown';

import CustomIcon from 'components/Icons';
import { SERVICE_QUICK_ACTIONS } from 'components/Constants/serviceConstants';

const ServiceQuickActions = ({
	rowData,
	setEnableActionModal,
	setSelectedAction,
}) => {

	const [show, setShow] = useState(false);
	const target = useRef(null);

	const quickActionsArr = useMemo(() => {
		if ((rowData?.status.toLowerCase() === "running") || rowData?.status.toLowerCase() === "stopped") {
			return SERVICE_QUICK_ACTIONS.filter((item) => rowData?.status.toLowerCase() === "running" ? item?.value !== "Start" : item?.value !== "Stop")
		}
		return SERVICE_QUICK_ACTIONS
	}, [show])
	
	const handleActionClicked = (eventKey, event, rowData) => {
		event.stopPropagation();
		event.preventDefault();
		setEnableActionModal(true);
		setSelectedAction({ eventType: eventKey, selectedActionRow: rowData?._id, wholeRow: rowData, type: 'individual' })
		setShow(false);
	}

	return (
		<div>
			<div className="px-2 d-flex align-items-center dot" onClick={() => { setShow(!show) }}>
				<div className={`col-action-button text-primary cursor-pointer dot ${show ? 'bay-icon-editable active' : ''}`} ref={target}>
					<CustomIcon icon="vertical-ellipsis" size={16} />
				</div>
			</div>
			<Overlay target={target.current} show={show} placement="right-start" onHide={() => setShow(false)} rootClose={true}>
				{({ placement, arrowProps, show: _show, popper, ...props }) => {
					return <div {...props} style={{ zIndex: 10, ...props.style, top: 30, left: -170 }}>
						<Dropdown autoClose align="right-start" show={true} id={'row_dropdown_' + rowData?.testimonialId} className="d-flex">
							<Dropdown.Menu show={true}>
								{[...quickActionsArr, { value: "Acknowledgement", label: "Acknowledge Issue", icon: <CustomIcon icon="mail" size={20} /> }].map((option, index) => {
									return <Dropdown.Item key={index} as={'span'} className="cursor-pointer my-2" eventKey="edit"
										onClick={(event) => { handleActionClicked(`${option.value}`, event, rowData) }}
										disabled={option.disabled}
									>
										{option.icon} {" "}{" "}
										{option.label}
									</Dropdown.Item>
								})}
							</Dropdown.Menu>
						</Dropdown>
					</div>
				}}
			</Overlay>
		</div>
	)
}


export default ServiceQuickActions;
