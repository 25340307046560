import { toast } from 'react-toastify';

export const REMOVE_HTML_FROM_STRING = (string) => {
	let regex = /(<([^>]+)>)/ig
	return string.replace(regex, "");
}

export const BAY_TOAST= (type, text) => {
	return toast?.[type](REMOVE_HTML_FROM_STRING(text), {
		position: "top-right",
		autoClose: 1000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "colored",
	})
}