import React from 'react';
import { Container } from 'react-bootstrap';
import { TopNav } from '../navigation/TopNav';

export const PageContainer = (props) => {
	return (
		<>
			<TopNav />
			{/* TopNav is 60px high */}
			<Container className="p-0" style={{minHeight: 'calc(100vh - 60px)'}} fluid id={props.id + '_page'}>
				{props.children}
			</Container>
		</>
	);
};
