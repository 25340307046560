import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { uniqBy } from 'lodash';

import BaySelect from '@back-at-you-inc/bay-ui/dist/BaySelect';

const ServiceFilterForm = ({
    services,
    setFilterDrawerData,
    clearDrawerFilter,
    setMainFilterData,
    mainFilterData,
}) => {

    const handleApplyFilter = () => {
        const { health, image, status, host, name, serviceName } = mainFilterData
        setFilterDrawerData({
            health: health?.length ? health.map(val => val.value.toLowerCase()) : [],
            serviceName: serviceName?.length ? serviceName.map(val => val.value.toLowerCase()) : [],
            image: image?.length ? image.map(val => val.value.toLowerCase()) : [],
            name: name?.length ? name.map(val => val.value.toLowerCase()) : [],
            host: host?.length ? host.map(val => val.value.toLowerCase()) : [],
            status: status?.length ? status.map(val => val.value.toLowerCase()) : []
        })
    }
    const handleOnFilterChange = (selectedValue, val) => {
        const { name } = val;
        setMainFilterData((prevUser) => ({
            ...prevUser,
            [name]: selectedValue,
        }));
    }
    return (
        <div>
            <Form id="service_list">
                <Form.Group className="py-2">
                    <Form.Label>Health</Form.Label>
                    <BaySelect
                        onChangeFunc={handleOnFilterChange}
                        name='health'
                        isMulti
                        isClearable
                        optionsArray={
                            uniqBy(services, 'health')?.map((e, i) => {
                                return {
                                    label: e.health,
                                    value: e.health
                                };
                            })}
                        defaultValue={mainFilterData?.health}
                        className="filter-dropdown"
                    />
                </Form.Group>
                <Form.Group className="py-2">
                    <Form.Label>Image</Form.Label>
                    <BaySelect
                        onChangeFunc={handleOnFilterChange}
                        name='image'
                        isMulti
                        isClearable
                        optionsArray={services?.map((e, i) => {
                            return {
                                label: e.image,
                                value: e.image
                            };
                        })}
                        defaultValue={mainFilterData?.image}
                        className="filter-dropdown"
                    />
                </Form.Group>
                <Form.Group className="py-2">
                    <Form.Label>Name</Form.Label>
                    <BaySelect
                        onChangeFunc={handleOnFilterChange}
                        name='name'
                        isMulti
                        isClearable
                        optionsArray={services?.map((e, i) => {
                            return {
                                label: e.name,
                                value: e.name
                            };
                        })}
                        defaultValue={mainFilterData?.name}
                        className="filter-dropdown"
                    />
                </Form.Group>
                <Form.Group className="py-2">
                    <Form.Label>Service Name</Form.Label>
                    <BaySelect
                        onChangeFunc={handleOnFilterChange}
                        name='serviceName'
                        isMulti
                        isClearable
                        optionsArray={services?.map((e, i) => {
                            return {
                                label: e.serviceName,
                                value: e.serviceName
                            };
                        })}
                        defaultValue={mainFilterData?.serviceName}
                        className="filter-dropdown"
                    />
                </Form.Group>
                <Form.Group className="py-2">
                    <Form.Label>Host</Form.Label>
                    <BaySelect
                        onChangeFunc={handleOnFilterChange}
                        name='host'
                        isMulti
                        isClearable
                        optionsArray={uniqBy(services, 'host')?.map((e, i) => {
                            return {
                                label: e.host,
                                value: e.host
                            };
                        })}
                        defaultValue={mainFilterData?.host}
                        className="filter-dropdown"
                    />
                </Form.Group>
                <Form.Group className="py-2">
                    <Form.Label>Status</Form.Label>
                    <BaySelect
                        onChangeFunc={handleOnFilterChange}
                        name='status'
                        isMulti
                        isClearable
                        optionsArray={uniqBy(services, 'status')?.map((e, i) => {
                            return {
                                label: e.status,
                                value: e.status
                            };
                        })}
                        defaultValue={mainFilterData?.status}
                        className="filter-dropdown"
                    />
                </Form.Group>
                <div className='border-top p-3'>
                    <div className="d-flex align-items-center justify-content-between">
                        <Form.Group className="form-item">
                            <Button
                                variant="link"
                                className="me-4 btn-clear"
                                onClick={() => clearDrawerFilter()}
                            >
                                Clear All
                            </Button>
                        </Form.Group>
                        <Form.Group className="form-item d-flex align-items-center">
                            <Button
                                variant="primary"
                                className="btn-filter"
                                onClick={() => handleApplyFilter()}
                            >
                                Apply Filter
                            </Button>
                        </Form.Group>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default ServiceFilterForm
